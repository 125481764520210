
















































import Vue, { PropType } from "vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { format_overall_score } from "@/utils/global";
import CandidateCard from "@/components/recruiter/admin/CandidateCard.vue";
import { SearchedCandidate } from "@/store/modules/recruiter/interfaces";
import { ViewCandidatesOptions } from "@/interfaces/recruiter/admin/all_candidates";
import OnBoardingInvitationCard from "@/components/recruiter/OnBoardingInvitationCard.vue";
import { mapActions } from "vuex";
import { USER_DETAILS_BY_ID } from "@/store/modules/auth/constants";
import { User } from "@/interfaces/data_objects/user";

export default Vue.extend({
  name: "ViewCandidatesBody",
  components: { DataNotFound, CandidateCard, OnBoardingInvitationCard },
  data() {
    return {
      candidates: [] as SearchedCandidate[],
      on_boarding_invitation: false,
      user_object: {} as User
    };
  },
  computed: {
    ViewCandidatesOptions() {
      return ViewCandidatesOptions;
    }
  },
  props: {
    matching_candidates: {
      type: Array as PropType<SearchedCandidate[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    matching_candidates(n) {
      if (n.length > 0) {
        this.candidates = this.matching_candidates.filter(
          (val: SearchedCandidate) => val
        );
      } else this.candidates = [];
    }
  },
  methods: {
    format_overall_score,
    ...mapActions("auth", {
      fetch_user_by_id: USER_DETAILS_BY_ID
    }),
    async view_profile(user: SearchedCandidate) {
      if (user) {
        const response = await this.fetch_user_by_id(user.candidate_id);
        this.user_object = response;
        this.on_boarding_invitation = true;
      }
    },
    on_close() {
      this.on_boarding_invitation = false;
      this.user_object = {} as User;
    }
  }
});
